import React from 'react';
import {
  Container,
  Dropdown,
  Image,
  Menu,
  Responsive,
} from 'semantic-ui-react';
import TopHeaderResp from './HeaderResp';
import {Heading1, Body2} from '../value/Styled';
import { useTranslation } from 'react-i18next';
import styles from './Header.module.css';

const TopHeader = () => {

  const { t, i18n } = useTranslation();

  function handleClick(lang){
    i18n.changeLanguage(lang);
  }

return(
  <div className={styles.headerMenu}>
    <Responsive minWidth={992}>
       <nav className={styles.headerLanguages}>
        <button onClick={()=>handleClick('pl')} className={styles.headerLanguagesButton}>
          <img alt='pl' src='icons/pl.svg' className={styles.headerFlags}/>
        </button>
        <button onClick={()=>handleClick('en')} className={styles.headerLanguagesButton}>
          <img alt='gb' src='icons/gb.png' className={styles.headerFlags}/>
        </button>
      </nav> 
    <Menu fixed='top'
     size='huge'
     className={styles.topMenu}
     inverted
     borderless
     floated
     >
      <Container>
      <Menu.Item as='a' href='/' header >   
        <Image className={styles.logo} src='images/logoTrak.svg' />
        <nav className={styles.navigation}>
        <Heading1 className={styles.title}><b> TMD TRAK</b> Sp. z o.o. </Heading1>
        <Body2 className={styles.titleBody}>{t('Title.2')}</Body2>
        </nav>
      </Menu.Item>
      <Menu.Item className={styles.menuHover} 
      name='home'
      position='right' 
      as='a' 
      href='/'>
        {t('Home.1')}
      </Menu.Item>
        <Dropdown  
          className={styles.menuHover} 
          name='company'
          simple
          item 
          text= {t('Company.1')}
          href="/company" 
        >
          <Dropdown.Menu>
            <Dropdown.Item href='/company'>{t('Company.2')}</Dropdown.Item>
            <Dropdown.Item href='/certificates'>{t('Company.Certificates')}</Dropdown.Item>
            <Dropdown.Item href='/galler'>{t('Gallery.1')}</Dropdown.Item>
            <Dropdown.Item href='/news'>{t('Company.News')}</Dropdown.Item>
            <Dropdown.Item href='/RODO'>{t('Company.Rodo')}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown 
        name='products'
        className={styles.menuHover} 
        simple 
        item  
        text={t('Products.1')} 
        href="/products" 
        >
          <Dropdown.Menu>
          <Dropdown.Item 
          href='/products'
          >
            {t('Products.1')}
          </Dropdown.Item>
            <Dropdown.Divider></Dropdown.Divider>
            <Dropdown.Item href='/products--pellet-drzewny'>{t('Products.PelletTitle')}</Dropdown.Item>
            <Dropdown.Item href='/products--klejonka'>{t('Products.Klejonka')}</Dropdown.Item>
            <Dropdown.Item href='/products--tralki'>{t('Products.TralkiTitle')}</Dropdown.Item>
            <Dropdown.Item href='/products--słupy-schodowe'>{t('Products.SlupyTitle')}</Dropdown.Item>
            <Dropdown.Item href='/products--trepy-schodowe'>{t('Products.TrepyTitle')}</Dropdown.Item>
            <Dropdown.Item href='/products--tarcica'>{t('Products.Tarcica')}</Dropdown.Item>
            <Dropdown.Item href='/products--boazeria'>{t('Products.BoazeriaTitle')}</Dropdown.Item>
            <Dropdown.Item href='/products--deska-tarasowa'>{t('Products.Deska_tTitle')}</Dropdown.Item>
            <Dropdown.Item href='/products--szalówka'>{t('Products.SzalowkaTitle')}</Dropdown.Item>
            <Dropdown.Item href='/products--deska-podłogowa'>{t('Products.Deska_pTitle')}</Dropdown.Item>
            <Dropdown.Item href='/products--blaty'>{t('Products.BlatyTitle')}</Dropdown.Item>
            <Dropdown.Item href='/products--poręcze'>{t('Products.PoreczeTitle')}</Dropdown.Item>
            <Dropdown.Item href='/products--łaty-i-kontrłaty'>{t('Products.Laty')}</Dropdown.Item>
            <Dropdown.Item href='/products--kora-ozdobna'>{t('Products.KoraTitle')}</Dropdown.Item>
            <Dropdown.Item href='/products--drewno-opałowe'>{t('Products.DrewnoTitle')}</Dropdown.Item>
            <Dropdown.Item href='/products--trociny-pod-borówkę'>{t('Products.Borowka')}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      <Menu.Item 
      name='contact'
      className={styles.menuHover} 
      as='a' 
      href='/contact'
      >
        {t('Contact.1')}
      </Menu.Item> 
      </Container>
    </Menu>
    </Responsive>

    <TopHeaderResp/>

</div>
)};

export default TopHeader
