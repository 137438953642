import React from 'react';
import styles from './Header.module.css';
import { useTranslation } from 'react-i18next';

import {
    Dropdown,
    Menu
  } from 'semantic-ui-react';

 const HeaderItemsResp = () => {
  const { t, i18n } = useTranslation();
  function handleClick(lang){
    i18n.changeLanguage(lang);
  }
    return (
        <div>
          <button onClick={()=>handleClick('pl')} className={styles.headerLanguagesButton}>
            <img alt='pl' src='icons/pl.svg' className={styles.headerFlags}/>
          </button>
          <button onClick={()=>handleClick('en')} className={styles.headerLanguagesButton}>
            <img alt='gb' src='icons/gb.png' className={styles.headerFlags}/>
          </button>
             <Menu.Item as='a' href='/'>
             {t('Home.1')}
        </Menu.Item>
        <Dropdown direction='left' className={styles.dropdownMenu}  simple item text= {t('Company.1')}>
          <Dropdown.Menu >
            <Dropdown.Item href='/company'>{t('Company.2')}</Dropdown.Item>
            <Dropdown.Item href='/certificates'>{t('Company.Certificates')}</Dropdown.Item>
            <Dropdown.Item as='a' href='/galler'> {t('Gallery.1')}</Dropdown.Item>
            <Dropdown.Item href='/news'>{t('Company.News')}</Dropdown.Item>
            <Dropdown.Item href='/RODO'>{t('Company.Rodo')}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown direction='left' className={styles.dropdownMenu} simple item text={t('Products.1')} >
          <Dropdown.Menu >
            <Dropdown.Item href='/products'>{t('Products.1')}</Dropdown.Item>
            <Dropdown.Divider></Dropdown.Divider>
            <Dropdown.Item href='/products--pellet-drzewny'>{t('Products.PelletTitle')}</Dropdown.Item>
            <Dropdown.Item href='/products--klejonka'>{t('Products.Klejonka')}</Dropdown.Item>
            <Dropdown.Item href='/products--tralki'>{t('Products.TralkiTitle')}</Dropdown.Item>
            <Dropdown.Item href='/products--słupy-schodowe'>{t('Products.SlupyTitle')}</Dropdown.Item>
            <Dropdown.Item href='/products--trepy-schodowe'>{t('Products.TrepyTitle')}</Dropdown.Item>
            <Dropdown.Item href='/products--tarcica'>{t('Products.Tarcica')}</Dropdown.Item>
            <Dropdown.Item href='/products--boazeria'>{t('Products.BoazeriaTitle')}</Dropdown.Item>
            <Dropdown.Item href='/products--deska-tarasowa'>{t('Products.Deska_tTitle')}</Dropdown.Item>
            <Dropdown.Item href='/products--szalówka'>{t('Products.SzalowkaTitle')}</Dropdown.Item>
            <Dropdown.Item href='/products--deska-podłogowa'>{t('Products.Deska_pTitle')}</Dropdown.Item>
            <Dropdown.Item href='/products--blaty'>{t('Products.BlatyTitle')}</Dropdown.Item>
            <Dropdown.Item href='/products--poręcze'>{t('Products.PoreczeTitle')}</Dropdown.Item>
            <Dropdown.Item href='/products--łaty-i-kontrłaty'>{t('Products.Laty')}</Dropdown.Item>
            <Dropdown.Item href='/products--kora-ozdobna'>{t('Products.KoraTitle')}</Dropdown.Item>
            <Dropdown.Item href='/products--drewno-opałowe'>{t('Products.DrewnoTitle')}</Dropdown.Item>
            <Dropdown.Item href='/products--trociny-pod-borówkę'>{t('Products.Borowka')}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      <Menu.Item as='a' href='/contact'>{t('Contact.1')}</Menu.Item> 
        </div>
    )
}
export default HeaderItemsResp;