import React from 'react';
import {Grid} from 'semantic-ui-react';
import {Heading3, Body1} from '../../value/Styled';
import MainButton from '../../Buttons/MainButton';
import { useTranslation } from 'react-i18next';
import styles from './Menu.module.css';

const Menu = () => {
  const { t } = useTranslation();
  return (
    <Grid.Column computer={5} mobile={1}>
    <div className={styles.menu}>
      <Heading3 className={styles.menuHead}>{t('Products.1')}:</Heading3>
      <Body1 className={styles.menuBody}>
        <a href='/products--pellet-drzewny'>{t('Products.PelletTitle')}</a>
        <a href='/products--klejonka'>{t('Products.Klejonka')}</a>
        <a href='/products--tralki'>{t('Products.TralkiTitle')}</a>
        <a href='/products--słupy-schodowe'>{t('Products.SlupyTitle')}</a>
        <a href='/products--trepy-schodowe'>{t('Products.TrepyTitle')}</a>
        <a href='/products--tarcica'>{t('Products.Tarcica')}</a>
        <a href='/products--boazeria'>{t('Products.BoazeriaTitle')}</a>
        <a href='/products--deska-tarasowa'>{t('Products.Deska_tTitle')}</a>
        <a href='/products--szalówka'>{t('Products.SzalowkaTitle')}</a>
        <a href='/products--deska-podłogowa'>{t('Products.Deska_pTitle')}</a>
        <a href='/products--blaty'>{t('Products.BlatyTitle')}</a>
        <a href='/products--poręcze'>{t('Products.PoreczeTitle')}</a>
        <a href='/products--łaty-i-kontrłaty'>{t('Products.Laty')}</a>
        <a href='/products--kora-ozdobna'>{t('Products.KoraTitle')}</a>
        <a href='/products--drewno-opałowe'>{t('Products.DrewnoTitle')}</a>
        <a href='/products--trociny-pod-borówkę'>{t('Products.Borowka')}</a>
      </Body1>
      <a href="/contact">
        <MainButton className={styles.menuButton}>
          {t('Contact.1')}
        </MainButton> 
      </a>
    </div>
  </Grid.Column>
    );
};

export default Menu;
