import React, { Component } from 'react';
import ReactGa from "react-ga";
import ProductPage from '../../ProductPage';
import {Heading2, Body1} from '../../value/Styled';
import { withTranslation } from 'react-i18next';
import styles from './Blaty.module.css';

class Blaty extends Component {

  componentDidMount() {
    ReactGa.pageview(window.location.pathname)
  }

  state={
    photos: [
      {
        path: `images/blat1.jpg`,
      }, 
      {
        path: `images/blat2.jpg`,
      }, 
      {
        path: `images/blat3.jpg`,
      }, 
    ],
    products: [
      {
        path: `images/pellet1.jpg`,
        url: `/products--pellet-drzewny`,
        title: 'Pellet',
      }, 
      {
        path: `images/klej1.jpg`,
        url: `/products--klejonka`,
        title: 'Klejonka',
      },
      {
        path: `images/tralka1.jpg`,
        url: `/products--tralki`,
        title: 'Tralki',
      }, 
    ]
  }
  render(){
    const { t }= this.props;
  return (
    <ProductPage photos={this.state.photos} products={this.state.products}>
    <Heading2 className={styles.green}>
    {t('Products.BlatyTitle')}
    </Heading2>
    <Body1 className={styles.productTextBody}>
    {t('Products.BlatyText.0')} 
      <br/>
      <br/>
      <b>
      {t('Products.BlatyText.1')} 
      </b>
      <br/>
      <br/>
      {t('Products.BlatyText.2')} 
    </Body1>
  </ProductPage>
    );
}};

export default withTranslation()(Blaty);
