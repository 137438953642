import React, { Component } from 'react';
import ReactGa from "react-ga";
import { Grid, Modal, Image, Divider } from 'semantic-ui-react';
import PEFC_text from './PEFC_text';
import FSC_text from './FSC_text';
import ENplus_text from './ENplus_text';
import Swiad_text from './Swiad_text';
import Title from './Title';
import styles from './Certificates.module.css'

class Certificates extends Component {

  componentDidMount() {
    ReactGa.pageview(window.location.pathname)
  }

  state = {
    certy: [
      {
        path: `certy/enplusv2.jpg`,
      }, 
      {
        path: `certy/ITD1.jpg`,
      }, 
      {
        path: `certy/ś_suszenie.jpg`,
      }, 
      {
        path: `certy/fsc1.jpg`,
      },
      {
        path: `certy/fsc2.jpg`,
      }, 
      {
        path: `certy/fsc3.jpg`,
      }, 
    ]
  };
  render() {
    return (
      <div className={styles.certy}>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Title/>
          </Grid.Column>
        </Grid.Row>

        <Divider/>

        <Grid.Row columns={2} className={styles.certyRow}> 
          <Grid.Column mobile={16} computer={7}>
            <FSC_text/>
          </Grid.Column>
        <Grid.Row></Grid.Row>
          <Grid.Column className={styles.certyColumn} mobile={13} computer={3}>
                <div className={styles.certyBack}></div>
                <Modal trigger={<img src="certy/fsc1.jpg" alt="" className={styles.certyPhoto}/>}>
                  <Modal.Content image>
                    <Image
                    fluid
                    src="certy/fsc1.jpg"
                    />
                  </Modal.Content>
                </Modal>
                <img src="certy/fsc1.jpg" alt="" className={styles.certyPhoto1}/>
          </Grid.Column>  
          <Grid.Column className={styles.certyColumn} mobile={13} computer={3}>
            <div className={styles.certyBack}></div>
            <Modal trigger={<img src="certy/fsc2.jpg" alt="" className={styles.certyPhoto}/>}>
              <Modal.Content image>
                <Image
                fluid
                src="certy/fsc2.jpg"
                />
              </Modal.Content>
            </Modal>
            <img src="certy/fsc2.jpg" alt="" className={styles.certyPhoto1}/>
          </Grid.Column>
          <Grid.Column className={styles.certyColumn} mobile={13} computer={3}>
            <div className={styles.certyBack}></div>
            <Modal trigger={<img src="certy/fsc3.jpg" alt="" className={styles.certyPhoto}/>}>
              <Modal.Content image>
                <Image
                fluid
                src="certy/fsc3.jpg"
                />
              </Modal.Content>
            </Modal>
            <img src="certy/fsc3.jpg" alt="" className={styles.certyPhoto1}/>
          </Grid.Column>
        </Grid.Row> 

        <Divider/>

        <Grid.Row columns={2} className={styles.certyRow}> 
          <Grid.Column mobile={16} computer={7}>
            <ENplus_text/>
          </Grid.Column>
        <Grid.Row></Grid.Row>
          <Grid.Column className={styles.certyColumn} mobile={13} computer={3}>
                <div className={styles.certyBack}></div>
                <Modal trigger={<img src="certy/enplusv2.jpg" alt="" className={styles.certyPhoto}/>}>
                  <Modal.Content image>
                    <Image
                    fluid
                    src="certy/enplusv2.jpg"
                    />
                  </Modal.Content>
                </Modal>
                <img src="certy/enplusv2.jpg" alt="" className={styles.certyPhoto1}/>
          </Grid.Column>  
        </Grid.Row> 

        <Divider/>

        <Grid.Row columns={2} className={styles.certyRow}> 
          <Grid.Column mobile={16} computer={7}>
            <PEFC_text/>
          </Grid.Column>
        <Grid.Row></Grid.Row>
          <Grid.Column className={styles.certyColumn} mobile={13} computer={3}>
                <div className={styles.certyBack}></div>
                <Modal trigger={<img src="certy/PEFC1.jpg" alt="" className={styles.certyPhoto}/>}>
                  <Modal.Content image>
                    <Image
                    fluid
                    src="certy/PEFC1.jpg"
                    />
                  </Modal.Content>
                </Modal>
                <img src="certy/PEFC1.jpg" alt="" className={styles.certyPhoto1}/>
          </Grid.Column>  
          <Grid.Column className={styles.certyColumn} mobile={13} computer={3}>
                <div className={styles.certyBack}></div>
                <Modal trigger={<img src="certy/PEFC2.jpg" alt="" className={styles.certyPhoto}/>}>
                  <Modal.Content image>
                    <Image
                    fluid
                    src="certy/PEFC2.jpg"
                    />
                  </Modal.Content>
                </Modal>
                <img src="certy/PEFC2.jpg" alt="" className={styles.certyPhoto1}/>
          </Grid.Column> 
          <Grid.Column className={styles.certyColumn} mobile={13} computer={3}>
            <div className={styles.certyBack}></div>
            <Modal trigger={<img src="certy/PEFC3.jpg" alt="" className={styles.certyPhoto}/>}>
              <Modal.Content image>
                <Image
                fluid
                src="certy/PEFC3.jpg"
                />
              </Modal.Content>
            </Modal>
            <img src="certy/PEFC3.jpg" alt="" className={styles.certyPhoto1}/>
          </Grid.Column>
        </Grid.Row> 

        <Divider/>

        <Grid.Row columns={2} className={styles.certyRow}> 
          <Grid.Column mobile={16} computer={7}>
           <Swiad_text/>
          </Grid.Column>
        <Grid.Row></Grid.Row>
          <Grid.Column className={styles.certyColumn} mobile={13} computer={3}>
                <div className={styles.certyBack}></div>
                <Modal trigger={<img src="certy/ś_suszenie.jpg" alt="" className={styles.certyPhoto}/>}>
                  <Modal.Content image>
                    <Image
                    fluid
                    src="certy/ś_suszenie.jpg"
                    />
                  </Modal.Content>
                </Modal>
                <img src="certy/ś_suszenie.jpg" alt="" className={styles.certyPhoto1}/>
          </Grid.Column>  
        </Grid.Row> 
      </Grid>
      </div>
      );
  }
}

export default Certificates;
