import React, {Component} from 'react';
import ReactGa from "react-ga";
import ProductPage from '../../ProductPage';
import {Heading2, Body1} from '../../value/Styled';
import { withTranslation } from 'react-i18next';
import styles from './Tralki.module.css';

class Tralki extends Component {

  componentDidMount() {
    ReactGa.pageview(window.location.pathname)
  }


  state={
    photos: [
      {
        path: `images/tralka1.jpg`,
      },
      {
        path: `images/tralka2.jpg`,
      }, 
      {
        path: `images/tralka3.jpg`,
      }, 
      {
        path: `images/tralka4.jpg`,
      }, 
      {
        path: `images/tralka5.jpg`,
      },  
    ],
    products: [
      {
        path: `images/pellet1.jpg`,
        url: `/products--pellet-drzewny`,
        title: 'Pellet',
      }, 
      {
        path: `images/klej1.jpg`,
        url: `/products--klejonka`,
        title: 'Klejonka',
      },
      {
        path: `images/slup1.jpg`,
        url: `/products--słupy-schodowe`,
        title: 'Słupy',
      }, 
    ]
  }
  render(){
    const { t }= this.props;
  return (
    <ProductPage photos={this.state.photos} products={this.state.products}>
      <Heading2 className={styles.green}>
      {t('Products.TralkiTitle')}
      </Heading2>
      <Body1 className={styles.productTextBody}>
      {t('Products.TralkiText.0')}
        <br/>
        <br/>
        {t('Products.TralkiText.1')}
        <br/>
        <br/>
        {t('Products.TralkiText.2')}
        <ul>
        <li><b> 41mm  x 41mm x 900/1100mm </b></li>
        <li><b> 40mm  x 40mm x 900/1100mm </b></li>
        <li><b> 38mm  x 38mm x 900/1100mm </b></li>
        <li><b> 32mm  x 32mm x 900/1100mm </b></li>
        </ul>
        <b>{t('Products.TralkiText.3')}</b>
        <br/>
        <br/>
        {t('Products.TralkiText.4')}
      </Body1>
    </ProductPage>
    );
}};

export default withTranslation()(Tralki);