import React from 'react';
import styles from './Footer.module.css';
import {Grid, Image} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import {Body1, Body3} from '../value/Styled';

const Footer = () => {
  const { t } = useTranslation();
    return (
    <div className={styles.footer}>
      <Grid>
        <Grid.Row columns={4}>
          <Grid.Column computer={8} mobile={16} className={styles.footerLogo}>
            <Image className={styles.logo} src='images/logoTrak.svg'/>
             <Body1 className={styles.footerBody}>
              <b>TMD TRAK Sp. z o. o.</b><br/>
               <ul>
                <li>{t("Footer.Pros1")}</li>
                <li>{t("Footer.Pros2")}</li>
                <li>{t("Footer.Pros3")}</li>
               </ul>
             </Body1>
          </Grid.Column>
          <Grid.Column computer={7} mobile={16} className={styles.footerColumn}>    
            <Body1 className={styles.footerNav}>
              <b className={styles.footerB}>
                <u>
                {t("Shorts.Nav")}
                </u>
              </b>
              <br/>
              <a href="/">
                {t("Home.1")}
              </a><br/>
              <a href="/company">
              {t("Company.1")}
              </a><br/>
              <a href="/products">
              {t("Products.1")}
              </a><br/>
              <a href="/galler">
              {t("Gallery.1")}
              </a><br/>
              <a href="/contact">
              {t("Contact.1")}
              </a><br/>
            </Body1>
            <Body1 className={styles.footerNav}>
            <b className={styles.footerB}>
              <u>
              {t("Contact.1")}
              </u>
            </b><br/>
            tel. +48 62 11 111
              <br/>
              <br/>
            trak1@trak-garbatka.pl
              <br/>
              <br/>
              <b>
              {t("Shorts.Days")} 07:00-16:00
              </b>

            </Body1>          
            <Body1 className={styles.footerNav}>
             <b className={styles.footerB}>
               <u>
               {t("Shorts.Address")}
                </u>
              </b>
              <br/>
               Garbatka Długa 1
               <br/><br/>
               26-930 Garbatka Letnisko
               <br/><br/>
               {t("Shorts.Poland")}
            </Body1>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row className={styles.copyright}>
          <Body3 className={styles.copyrightBody}>
          Copyright{/*<sup>©</sup>*/} 2020 Filip Kwaśnik
          </Body3>
        </Grid.Row>
      </Grid>
    </div>);
  }

export default Footer;
