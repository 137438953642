import React from 'react';
import styles from './Gallery.module.css';
import { Heading1 } from '../value/Styled';
import { useTranslation } from 'react-i18next';

const Title = () => {
    const { t } = useTranslation();
    return (
        <Heading1 className={styles.green}>{t('Gallery.1')}</Heading1>
    )
}
export default Title;