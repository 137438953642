import React, {Component} from 'react';
import ReactGa from "react-ga";
import ProductPage from '../../ProductPage';
import {Heading2, Body1} from '../../value/Styled';
import { withTranslation } from 'react-i18next';
import styles from './Drewno_opalowe.module.css';

class Drewno_opalowe extends Component {

  componentDidMount() {
    ReactGa.pageview(window.location.pathname)
  }

  state={
    photos: [
      {
        path: `images/drewno_o1.jpg`,
      }, 
      {
        path: `images/drewno_o2.jpg`,
      }, 
      {
        path: `images/drewno_o3.jpg`,
      }, 
    ],
    products: [
      {
        path: `images/pellet1.jpg`,
        url: `/products--pellet-drzewny`,
        title: 'Pellet',
      }, 
      {
        path: `images/klej1.jpg`,
        url: `/products--klejonka`,
        title: 'Klejonka',
      },
      {
        path: `images/tralka1.jpg`,
        url: `/products--tralki`,
        title: 'Tralki',
      }, 
    ]
  }
  render(){
    const { t }= this.props;
  return (
    <ProductPage photos={this.state.photos} products={this.state.products}>
      <Heading2 className={styles.green}>
      {t('Products.DrewnoTitle')}
      </Heading2>
      <Body1 className={styles.productTextBody}>
      {t('Products.DrewnoText')}
      </Body1>
    </ProductPage>
    );
}};

export default withTranslation()(Drewno_opalowe);