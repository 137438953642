import React from 'react';
import {Grid, } from 'semantic-ui-react';
import {Heading2, Heading3} from '../value/Styled';
import { useTranslation } from 'react-i18next';
import MainButton from '../Buttons/MainButton';
import styles from './Offer.module.css'

const Offer = () => {
  const { t } = useTranslation();
  return (

    <Grid.Row  className={styles.offer}>
     <Grid.Column width={16} className={styles.offerTitle}>
    </Grid.Column> 
    <Grid.Column computer={5} mobile={16}>
    <img src="images/offer01.png" alt="" className={styles.offerImg} />
    </Grid.Column>
    
    <Grid.Column computer={6} mobile={16} textAlign="center" className={styles.offerText} >
      <Heading2 className={styles.offerTitle}>
    {/* cos {t('Home.Banner')} */}
      <b>
      {t('Home.Special')}
      </b>
      </Heading2> 
      <Heading3 className={styles.offerSub}>
      {t('Home.DoNot')}
      </Heading3>
      <a href="https://www.olx.pl/oferty/uzytkownik/GXtp/">
        <MainButton>{t('Shorts.CheckHere')}</MainButton>
      </a>
    </Grid.Column>
    
    <Grid.Column computer={5} mobile={16}>
    <img src="images/offer02.png" alt="" className={styles.offerImg} />
  
    </Grid.Column>
</Grid.Row>
     
    
    );
};

export default Offer;
