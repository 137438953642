import {useEffect} from 'react';
import ReactGa from "react-ga";
import { Heading1, Heading2, Body1 } from '../value/Styled';
import { Grid, Divider } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import styles from './Contact.module.css'

const Contact = () => {

  useEffect(()=> {
    ReactGa.pageview(window.location.pathname)
  });


  const { t } = useTranslation();
    return (
      <div className={styles.contact}>
      <Grid>
        <Grid.Row>
          <Grid.Column>
          <Heading1 className={styles.green}>{t("Contact.1")}</Heading1>
          </Grid.Column>
        </Grid.Row>
        <Divider/>
        <Grid.Row columns={2} className={styles.contactRow}> 
          <Grid.Column computer={8} mobile={16}>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14050.97865623496!2d21.661053044710666!3d51.49696729705031!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4718850c979b7b23%3A0x5dcd7b7453540a52!2sTRAK!5e0!3m2!1spl!2spl!4v1584958409565!5m2!1spl!2spl" className={styles.googleMap} frameborder="0" title="garbatka"></iframe>
          </Grid.Column>
          <Grid.Column computer={8} mobile={16} className={styles.contactColumn}>
            <Heading2>
            {t("Shorts.Headquarters")}
            </Heading2>
            <Body1 className={styles.contactBody}>
              <b>
              {t("Shorts.Address")}:
              </b>
              <br/>
              Garbatka Długa 1
              <br/>
              26-930 Garbatka Letnisko
              <br/>
              {t("Shorts.Poland")}
              <br/>
              <br/>
              <b>
              Tel.:
              </b>
              <br/>
              +48 62 11 111
              <br/>
              +48 502 738 417
              <br/>
              +48 508 569 055
              <br/>
              <br/>
              <b>
              Mail:
              </b>
              <br/>
              trak@trak-garbatka.pl
              <br/>
              <br/>
              <b>Regon:</b> 672716198
              <br/>
              <b>NIP:</b> 8121761475
              <br/>
              <b>KRS:</b> 0000381889
            </Body1>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
         <Grid.Column>
          <img src="images/kontakt_g.jpg" alt="" className={styles.contactImg} />
         </Grid.Column>
        </Grid.Row>
        <Divider/>
        <Grid.Row columns={2} className={styles.contactRow} reversed="computer"> 
          <Grid.Column computer={8} mobile={16}>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19906.557483404573!2d21.652595619131347!3d51.41555390507259!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471886e8281a4587%3A0x24ae3694b04a699d!2sJadwin%C3%B3w%2032%2C%2026-720%20Jadwin%C3%B3w!5e0!3m2!1spl!2spl!4v1585912749741!5m2!1spl!2spl" className={styles.googleMap} frameborder="0" allowfullscreen="" title="jadwinow"></iframe>
          </Grid.Column>
          <Grid.Column computer={8} mobile={16} className={styles.contactColumn}>
            <Heading2>
            {t("Shorts.Branch")}
            </Heading2>
            <br/>
            <Body1 className={styles.contactBody}>
              <b>
              {t("Shorts.Address")}:
              </b>
              <br/>
              Jadwinów 32
              <br/>
              26-720 Policzna
              <br/>
              {t("Shorts.Poland")}
              <br/>
              <br/>
              <b>
              Tel.:
              </b>
              <br/>
              +48 67 80 506
              <br/>
              +48 516 722 638
              <br/>
              <br/>
              <b>
              Mail:
              </b>
              <br/>
              jadwinow@trak-garbatka.pl
            </Body1>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
          <img src="images/kontakt_j.jpg" alt="" className={styles.contactImg}/>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      </div>
      );
  }

export default Contact;
