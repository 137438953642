import React from 'react'
import { Body1 } from '../value/Styled';
import styles from './Certificates.module.css'

const PEFC_text = () => {
    return (
        <Body1 className={styles.certyBody}>
        {/*  <b>3. PEFC</b><sup>™</sup> -  system wdrożony w marcu 2020r. Produkty  z deklaracją PEFC dają klientom i konsumentom końcowym pewność, że dany surowiec pochodzi z lasów zarządzanych w zrównoważony sposób z kontrolowanych źródeł. Nasz nr certyfikatu PEFC to <b>CU-PEFC-862262</b>. */}
        </Body1>
    )
}
export default PEFC_text;