import React, { Component } from 'react';
import ReactGa from "react-ga";
import { Heading1, Heading2, Heading3, Body1 } from '../value/Styled';
import { Grid, Divider } from 'semantic-ui-react';
import ContactBanner from '../ContactBanner';
import { withTranslation } from 'react-i18next';
import styles from './Products.module.css'
import ProductBox from '../ProductBox';

class Products extends Component {

  componentDidMount() {
    ReactGa.pageview(window.location.pathname)
  }
  
  state = {
    products: [
      {
        path: `images/pellet1.jpg`,
        url: `/products--pellet-drzewny`,
        title: 'PelletTitle',
        text: "PelletShort"
      }, 
      {
        path: `images/klej1.jpg`,
        url: `/products--klejonka`,
        title: 'KlejonkaTitle',
        text: "KlejonkaShort"
      }, 
       {
        path: `images/tralka1.jpg`,
        url: `/products--tralki`,
        title: 'TralkiTitle',
        text: "TralkiShort"
      },
       {
        path: `images/slup1.jpg`,
        url: `/products--słupy-schodowe`,
        title: 'SlupyTitle',
        text: "SlupyShort"
      }, 
      {
        path: `images/trep1.jpg`,
        url: `/products--trepy-schodowe`,
        title: 'TrepyTitle',
        text: "TrepyShort"
      },
      {
        path: `images/tarcica1.jpg`,
        url: `/products--tarcica`,
        title: 'Tarcica',
        text: "TarcicaShort"
      }, 
      {
        path: `images/boazeria1.jpg`,
        url: `/products--boazeria`,
        title: 'BoazeriaTitle',
        text: "BoazeriaShort"
      }, 
      {
        path: `images/deska_t1.jpg`,
        url: `/products--deska-tarasowa`,
        title: 'Deska_tTitle',
        text: "Deska_tShort"
      }, 
      {
        path: `images/logoTrak.svg`,
        url: `/products--szalówka`,
        title: 'SzalowkaTitle',
        text: "SzalowkaShort"
      }, 
      {
        path: `images/deska_p1.jpg`,
        url: `/products--deska-podłogowa`,
        title: 'Deska_pTitle',
        text: "Deska_pShort"
      }, 
      {
        path: `images/logoTrak.svg`,
        url: `/products--blaty`,
        title: 'BlatyTitle',
        text: "BlatyShort"
      },    
      {
        path: `images/porecze1.jpg`,
        url: `/products--poręcze`,
        title: 'PoreczeTitle',
        text: "PoreczeShort"
      },
      {
        path: `images/laty1.jpg`,
        url: `/products--łaty-i-kontrłaty`,
        title: 'Laty',
        text: "LatyShort"
      }, 
      {
        path: `images/kora1.jpg`,
        url: `/products--kora-ozdobna`,
        title: 'KoraTitle',
        text: "KoraShort"
      }, 
            {
        path: `images/drewno_o1.jpg`,
        url: `/products--drewno-opałowe`,
        title: 'DrewnoTitle',
        text: "DrewnoShort"
      }, 
      {
        path: `images/borowka1.jpg`,
        url: `/products--trociny-pod-borówkę`,
        title: 'Borowka',
        text: "BorowkaShort"
      },
    ],
    materials:[
      {
        path: `images/sosna.jpg`,
        title: 'Sosna',
      },
      {
        path: `images/dab.jpg`,
        title: 'Dąb',
      },
      {
        path: `images/buk.jpg`,
        title: 'Buk',
      },
      {
        path: `images/olcha.jpg`,
        title: 'Olcha',
      },

    ]
  };
  render() {
  
    const { t }= this.props;
    return (
      <div className={styles.products}>
      <Grid>
        <Grid.Row>
          <Grid.Column>
          <Heading1 className={styles.green}>{t('Products.1')}</Heading1>
          </Grid.Column>
        </Grid.Row>
        <Divider className={styles.productsDivider}/>
        <Grid.Row columns={3}>
        <Grid.Column mobile={16} tablet={16} computer={2} >
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={6} textAlign="center" >
            <Body1>
              <b>
                Katalog po polsku
              </b>
            </Body1>
          <a href="Katalog TRAK.pdf"><img src="images/katalog.jpg" alt="Katalog TRAK" className={styles.catalogImg}/></a>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={6} textAlign="center">
            <Body1>
             <b>
               Catalog in english
             </b>
            </Body1>
           <a href="Catalog TRAK.pdf"><img src='images/catalog.jpg' alt="Catalog TRAK" className={styles.catalogImg}/> </a>
          </Grid.Column>
        </Grid.Row>

        <ContactBanner/>
        
        <Grid.Row columns={4} className={styles.productRow}> 
          {this.state.products.map((product, i) => (
            <Grid.Column className={styles.productColumn} mobile={16} tablet={5} computer={4} key={`${product.title}-${i}`}>
              <ProductBox product={product}/>
            </Grid.Column>
          ))}
        </Grid.Row>
        <Divider/>
        <Grid.Row>
          <Grid.Column>
          <Heading2 className={styles.green}>{t('Shorts.MaterialsFor')}</Heading2>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={4} className={styles.productRow}> 
          {this.state.materials.map((mats, i) => (
            <Grid.Column  mobile={16} computer={4} key={`${mats.title}-${i}`}>
              <div className={styles.materialColumn}>
              <img src={mats.path} alt="" className={styles.materialImg}/>
              <Heading3 className={styles.materialHead}>{t('Mats.' + mats.title)}</Heading3>
              </div>
            </Grid.Column>
          ))}
        </Grid.Row> 
      </Grid>
      </div> 
      );
  }
}

export default withTranslation()(Products);