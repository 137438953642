import React from 'react';
import styles from './MainButton.module.css';

const MainButton = (props) => {
  return (
    <button className={`${styles.mainButton} ${props.className}`}>
      {props.children}
    </button>
  );
};

export default MainButton;
