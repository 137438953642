import React, {Component} from 'react';
import { Heading3 } from '../value/Styled';
import styles from './ProductMin.module.css';

class ProductMin extends Component {
  render() {
    return (
            <a href={this.props.product.url}>
              <div className={styles.text}>
              <img src={this.props.product.path} alt="" className={styles.photo}/>
                <Heading3 className={styles.textHead}>{this.props.product.title}</Heading3>
              </div>
            </a>
    );
  }
}

export default ProductMin;
