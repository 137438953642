import React from 'react';
import {Grid, Divider} from 'semantic-ui-react';
import {Heading3} from '../value/Styled';
import MainButton from '../Buttons/MainButton';
import { useTranslation } from 'react-i18next';
import styles from './ContactBanner.module.css'

const Sale = () => {
  const { t } = useTranslation();
  return (
    <Grid.Row className={styles.sale}>
    <Grid.Column width={16} className={styles.saleInfo}>
      <Heading3 className={styles.saleText}>
        <b>
          {t('Contact.Banner')}
        </b>
      </Heading3>
      <a href="/contact">
        <MainButton className={styles.newsButton}>
          <b>
            {t('Contact.1')}
          </b>
        </MainButton>
      </a>
    </Grid.Column>
    <Divider/>
</Grid.Row>
    );
};

export default Sale;
