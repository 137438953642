import React, {Component} from 'react';
import ReactGa from "react-ga";
import ProductPage from '../../ProductPage';
import {Heading2, Body1} from '../../value/Styled';
import { withTranslation } from 'react-i18next';
import styles from './Tarcica.module.css';

class Tarcica extends Component {

  componentDidMount() {
    ReactGa.pageview(window.location.pathname)
  }

  state={
    photos: [
      {
        path: `images/tarcica1.jpg`,
      }, 
      {
        path: `images/tarcica2.jpg`,
      }, 
      {
        path: `images/tarcica3.jpg`,
      }, 
      {
        path: `images/tarcica4.jpg`,
      }, 
    ],
    products: [
      {
        path: `images/pellet1.jpg`,
        url: `/products--pellet-drzewny`,
        title: 'Pellet',
      }, 
      {
        path: `images/klej1.jpg`,
        url: `/products--klejonka`,
        title: 'Klejonka',
      },
      {
        path: `images/tralka1.jpg`,
        url: `/products--tralki`,
        title: 'Tralki',
      }, 
    ]
  }
  render(){
    const { t }= this.props;
  return (
    <ProductPage photos={this.state.photos} products={this.state.products}>
      <Heading2 className={styles.green}>
      {t('Products.TarcicaTitle')}
      </Heading2>
      <Body1 className={styles.productTextBody}>
      {t('Products.TarcicaText.0')}
      <br/>
      <br/>
      {t('Products.TarcicaText.1')}
      <br/>
      <br/>
      {t('Products.TarcicaText.2')}
      <br/>

      </Body1>
    </ProductPage>
    );
}};

export default withTranslation()(Tarcica);