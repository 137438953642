import React from 'react';
import { Body1 } from '../value/Styled';
import { useTranslation } from 'react-i18next';
import styles from './Certificates.module.css'

const Swiad_text = () => {
    const { t } = useTranslation();
    return (
        <Body1 className={styles.certyBody}>
            <b> 4. {t('Certificates.SwiadTitle')}  </b>  - {t('Certificates.Swiad')}  
        </Body1>
    )
}
export default Swiad_text;