import React, {Component} from 'react';
import ReactGa from "react-ga";
import ProductPage from '../../ProductPage';
import {Heading2, Body1} from '../../value/Styled';
import { withTranslation } from 'react-i18next';
import styles from './Deska_podlogowa.module.css';

class Deska_podlogowa extends Component {

  componentDidMount() {
    ReactGa.pageview(window.location.pathname)
  }

  state={
    photos: [
      {
        path: `images/deska_p1.jpg`,
      }, 
      {
        path: `images/deska_p2.jpg`,
      }, 
  {
        path: `images/deska_p3.jpg`,
      }, 
  {
        path: `images/deska_p4.jpg`,
      }, 
  {
        path: `images/deska_p5.jpg`,
      }, 
  {
        path: `images/deska_p6.jpg`,
      }, 

    ],
    products: [
      {
        path: `images/pellet1.jpg`,
        url: `/products--pellet-drzewny`,
        title: 'Pellet',
      }, 
      {
        path: `images/klej1.jpg`,
        url: `/products--klejonka`,
        title: 'Klejonka',
      },
      {
        path: `images/tralka1.jpg`,
        url: `/products--tralki`,
        title: 'Tralki',
      }, 
    ]
  }
  render(){
    const { t }= this.props;
  return (
    <ProductPage photos={this.state.photos} products={this.state.products}>
      <Heading2 className={styles.green}>
      {t('Products.Deska_pTitle')}
      </Heading2>
      <Body1 className={styles.productTextBody}>
      {/* {t('Products.Deska_pText.0')}
      <br/>
      <br/>
      {t('Products.Deska_pText.1')} <b>19mm x 170mm x 2500/2700/3000mm.</b>  */}
      {t('Shorts.Soon')}
      </Body1>
    </ProductPage>
    );
}};

export default withTranslation()(Deska_podlogowa);