import React from 'react';
import { Grid, Divider } from 'semantic-ui-react';
import { Heading2, Heading3 } from '../value/Styled';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import styles from './ProductPage.module.css';
import ProductMin from '../ProductMin';
import Menu from './Menu/Menu';
import MainButton from '../Buttons/MainButton';

const ProductPage = props => {

    const { t } = useTranslation();

    const SampleNextArrow = (props) => (
      <div onClick={props.onClick} className={styles.arrows}>
        <img src="/icons/next-1.svg" alt="<" />
      </div>
    );
    const SamplePrevArrow = (props) => (
      <div onClick={props.onClick} className={styles.arrows}>
        <img src="/icons/back.svg" alt=">" />
      </div>
    );

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      pauseOnHover: true,
    };
  return (
  <Grid className={styles.product}>
    <Grid.Row  columns={1} 
      style={{ minHeight: 170,
      background: 'linear-gradient(rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.5) 100%), url("images/produkty.jpg")', 
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 5%',
      backgroundSize: 'cover',
      }}
      >
        <Grid.Column className={styles.ProductPageStart} width={16}>
          <Heading2 className={styles.ProductPageStartTitle}>{t('Shorts.CheckForDetails')}</Heading2>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>

        <Menu/>

        <Grid.Column computer={10} mobile={16} className={styles.productText}>
          {props.children}

           <Slider {...settings}>
           {props.photos.map((photo, i) => (
            <img src={photo.path} alt="" key={`${photo.path}-${i}`} className={styles.productImg}/>
          ))}
          </Slider>

            <br/>
            <br/>
          <Heading3 className={styles.green}>
          {t('Shorts.ViewAll')}
          </Heading3>
          <div className={styles.productPageAll}>
          <a href="/products">
            <MainButton className={styles.productPageButton}>
            {t('Shorts.BackToProducts')}
            </MainButton> 
           </a>
          </div>
          
          <Divider/>

          <Heading3 className={styles.seeMore}>
            {t('Shorts.SeeAlso')}
          </Heading3>
          <br/>

          <section className={styles.more}>
            {props.products.map((product, i) => (
              <ProductMin product={product} key={`${product.title}-${i}`}/>
            ))}
          </section>

        </Grid.Column>
      </Grid.Row>
    </Grid>
)};

export default ProductPage;
