import React, {Component} from 'react';
import { Heading3, Body2 } from '../value/Styled';
import styles from './ProductBox.module.css';
import { withTranslation } from 'react-i18next';

class ProductBox extends Component {
  render() {
    const { t }= this.props;
    return (
            <a href={this.props.product.url}>
              <div className={styles.text}>
              <img src={this.props.product.path} alt="" className={styles.photo}/>
                <Heading3 className={styles.textHead}>{t('Products.' + this.props.product.title)}</Heading3>
                <Body2 className={styles.textBody}>
                {t('Products.' + this.props.product.text)}
                </Body2>
              </div>
            </a>
    );
  }
}

export default withTranslation()(ProductBox);
