import {useEffect} from 'react';
import ReactGa from "react-ga";
import {Grid, Responsive, Divider} from 'semantic-ui-react';
import {Heading1, Heading2,  Body1} from '../value/Styled';
import CompanyResp from './CompanyResp';
import MainButton from '../Buttons/MainButton';
import styles from './Company.module.css';
import { useTranslation } from 'react-i18next';
import {withRouter} from 'react-router-dom';

const Company = () =>{

  useEffect(()=> {
    ReactGa.pageview(window.location.pathname)
  });

  const { t } = useTranslation();
    return(
      <div className={styles.company}>
      <Responsive minWidth={992}>
      {/*------------------------Start----------------------- */}
      <Grid>
      <Grid.Row  columns={1} className={styles.companyRowStart}
      style={{ minHeight: 1000,
      background: 'url("images/company.jpg")', 
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      }}
      >
        <Grid.Column computer={8} mobile={16} textAlign='center' className={styles.companyColumnStart}>
          <div className={styles.companyStart}>
          <Heading1 className={styles.companyTitle}>
            {t("Company.Check.0")}
            <br/>
            {t("Company.Check.1")}
            <br/>
            {t("Company.Check.2")}
            <br/>
            {t("Company.Check.3")}
          </Heading1>
          <Body1 className={styles.companyTitleB}>{t("Company.Elite")}</Body1>
          </div>
        </Grid.Column>
      </Grid.Row>

    {/*------------------------Opis firmy----------------------- */}

      <Grid.Row columns={1}>    
        <Grid.Column width={16} className={styles.companyInfo}>
          <img src="images/z5.jpg" className={styles.companyInfoBack} alt=""/>
          <div className={styles.companyInfoText}>
            <Heading2 className={styles.companyInfoTextTitle}>{t("Company.Title")}</Heading2><br/>
            <Body1>
            {t("Company.Text.0")}
            <br/>
            <br/>
            {t("Company.Text.1")}
            <br/>
            </Body1><br/>
            <a href="/products"> 
            <MainButton className={styles.companyMainButton}>{t('Products.1')}</MainButton>
            </a>
          </div>
        </Grid.Column>
      </Grid.Row>

        {/*------------------------Więcej----------------------- */}

      <Grid.Row columns={1} className={styles.companyMoreInfo}>
        <Grid.Column>
        <div className={styles.companyMoreInfoBack}>
              <Heading2>{t("Shorts.Furthermore")}</Heading2><br/>
            <div className={styles.Boxs}>
              <div className={styles.Box}>
                <Body1>
                {t("Company.More.0")}
                </Body1>
                <Divider/>
              </div>
              <div className={styles.Box}>
                <Body1>
                {t("Company.More.1")}
                </Body1>
                <Divider/>
              </div>
              <div className={styles.Box}>
                <Body1>
                {t("Company.More.2")}
                  <br/>
                  {t("Company.More.3")}
                </Body1>
                <Divider/>
              </div>
            </div>
            <div className={styles.Boxs}>
              <div className={styles.Box}>
                <a href="/news">
                  <MainButton className={styles.companyButton}>
                  {t('Company.News')}
                  </MainButton> 
                </a>
              </div>
              <div className={styles.Box}>
                <a href="/certificates">
                  <MainButton className={styles.companyButton}>
                  {t('Company.Certificates')}
                  </MainButton> 
                </a>
              </div>
              <div className={styles.Box}>
                <a href="/Rodo">
                  <MainButton className={styles.companyButton}>
                  {t('Company.Rodo')}
                  </MainButton> 
                </a>
              </div>
            </div>
          </div>
        </Grid.Column>
      </Grid.Row>

        {/*-----------------------Projekty----------------------- */}

       {/* <Grid.Row columns={1} className={styles.companyBackground}>
        <Grid.Column textAlign="center" width={16}>
          <Heading2 className={styles.companyGreen}>
            Projekty
          </Heading2>
        </Grid.Column>
      </Grid.Row>   
      <Grid.Row columns={2} reversed="computer" className={styles.companyBackground}>
        <Grid.Column computer={7} className={styles.companyProjectImage}>
          <img src='images/wlasne8.jpg' className={styles.ImagesCover} alt=''/>
        </Grid.Column>
        <Grid.Column computer={7} className={styles.companyProjectColumn}>
          <Heading3 className={styles.companyGreen}>
            Nazwa projektu
          </Heading3>
          <Body1>
          To opis Twojego projektu. Niezależnie od tego, czy Twoja praca bazuje na tekście, fotografiach czy wideo, krótkie streszczenie na pewno pozwoli odwiedzającym witrynę lepiej poznać i zrozumieć Twój projekt. Wykorzystaj część poświęconą mediom, aby pochwalić się swoją pracą!
          </Body1>
        </Grid.Column>
      </Grid.Row>        
      <Grid.Row columns={2} reversed="computer" className={styles.companyBackground}>
        <Grid.Column computer={7} className={styles.companyProjectImage}>
          <img src='images/proba2.jpg' className={styles.ImagesCover} alt=''/>
        </Grid.Column>
        <Grid.Column computer={7} className={styles.companyProjectColumn}>
          <Heading3 className={styles.companyGreen}>
            Nazwa projektu
          </Heading3>
          <Body1>
          To opis Twojego projektu. Niezależnie od tego, czy Twoja praca bazuje na tekście, fotografiach czy wideo, krótkie streszczenie na pewno pozwoli odwiedzającym witrynę lepiej poznać i zrozumieć Twój projekt. Wykorzystaj część poświęconą mediom, aby pochwalić się swoją pracą!
          </Body1>
        </Grid.Column>
      </Grid.Row> 
      <Grid.Row columns={2} reversed="computer" className={styles.companyBackground}>
        <Grid.Column computer={7} className={styles.companyProjectImage}>
          <img src='images/proba4.jpg' className={styles.ImagesCover} alt=''/>
        </Grid.Column>
        <Grid.Column computer={7} className={styles.companyProjectColumn}>
          <Heading3 className={styles.companyGreen}>
            Nazwa projektu
          </Heading3>
          <Body1>
          To opis Twojego projektu. Niezależnie od tego, czy Twoja praca bazuje na tekście, fotografiach czy wideo, krótkie streszczenie na pewno pozwoli odwiedzającym witrynę lepiej poznać i zrozumieć Twój projekt. Wykorzystaj część poświęconą mediom, aby pochwalić się swoją pracą!
          </Body1>
        </Grid.Column>
      </Grid.Row>         */}
      </Grid>
      </Responsive>

      <CompanyResp/>
    
  </div>
    );
  }

export default withRouter(Company);