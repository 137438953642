import React from 'react';
import {Grid, Divider} from 'semantic-ui-react';
import {Heading3} from '../value/Styled';
import MainButton from '../Buttons/MainButton';
import { useTranslation } from 'react-i18next';
import styles from './ProductBanner.module.css'

const ProductBanner = () => {
  const { t } = useTranslation();
  return (
    <Grid.Row className={styles.sale}>
    <Grid.Column width={16} className={styles.saleInfo}>
      <Heading3 className={styles.saleText}>
          <i className={styles.saleNewText}>
          {t('Shorts.New')}
          </i>
          <b>
          {t('Home.Banner')}
          </b>
      </Heading3>
      <a href="/products">
        <MainButton className={styles.newsButton}>
          <b>
          {t('Shorts.CheckHere')}
          </b>
        </MainButton>
      </a>
    </Grid.Column>
    <Divider/>
</Grid.Row>
    );
};

export default ProductBanner;
