import React from 'react';
import { Body1 } from '../value/Styled';
import { useTranslation } from 'react-i18next';
import styles from './Certificates.module.css';

const FSC_text = () => {
    const { t } = useTranslation();
    return (
        <Body1 className={styles.certyBody}>
        <b>1. FSC</b><sup>®</sup>  - {t('Certificates.FSC')}  
        </Body1>
    )
}
export default FSC_text;