import {useEffect} from 'react';
import ReactGa from "react-ga";
import { Grid, Container } from 'semantic-ui-react';
import { Heading2, Body2 } from '../value/Styled';
import { useTranslation } from 'react-i18next';
import styles from './Rodo.module.css';

const Rodo = () => {

  useEffect(()=> {
    ReactGa.pageview(window.location.pathname)
  });


  const { t } = useTranslation();
  return (
    <Grid>
      <Container className={styles.rodo}>
        <Heading2 className={styles.rodoHead}>{t('Company.Rodo')}</Heading2>
        <Body2 className={styles.rodoBody}>
        {t('Rodo.0')}
        <ol>
        <li> {t('Rodo.1')}</li><br/>
        <li> {t('Rodo.2')} </li>
        <ul>
        <li>{t('Rodo.3')}</li>
        <li>{t('Rodo.4')}</li>
        <li>{t('Rodo.5')}</li>
        <li>{t('Rodo.6')}</li>
        <li>{t('Rodo.7')}</li>
        <li>{t('Rodo.8')}</li>
        <li>{t('Rodo.9')}</li>
        </ul><br/>
        <li>{t('Rodo.10')}</li>
        <ul>
        <li>{t('Rodo.11')}</li>
        <li>{t('Rodo.12')}</li>
        <li>{t('Rodo.13')}</li>
        <li>{t('Rodo.14')}</li>
        </ul><br/>
        <li>{t('Rodo.15')}</li><br/>
        <li>{t('Rodo.16')}</li><br/>
        <li>{t('Rodo.17')}</li>
        </ol>
        </Body2>
      </Container>
    </Grid>
    );
};

export default Rodo;
