import React, {Component} from 'react';
import ReactGa from "react-ga";
import ProductPage from '../../ProductPage';
import {Heading2, Body1} from '../../value/Styled';
import { withTranslation } from 'react-i18next';
import styles from './Boazeria.module.css';

class Boazeria extends Component {

  componentDidMount() {
    ReactGa.pageview(window.location.pathname)
  }

  state={
    photos: [
      {
        path: `images/boazeria1.jpg`,
      }, 
      {
        path: `images/boazeria2.jpg`,
      }, 
      {
        path: `images/boazeria3.jpg`,
      }, 
      {
        path: `images/boazeria4.jpg`,
      }, 
      {
        path: `images/boazeria5.jpg`,
      }, 
      {
        path: `images/boazeria6.jpg`,
      }, 
    ],
    products: [
      {
        path: `images/pellet1.jpg`,
        url: `/products--pellet-drzewny`,
        title: 'Pellet',
      }, 
      {
        path: `images/klej1.jpg`,
        url: `/products--klejonka`,
        title: 'Klejonka',
      },
      {
        path: `images/tralka1.jpg`,
        url: `/products--tralki`,
        title: 'Tralki',
      }, 
    ]
  }
  render(){
    const { t }= this.props;
  return (
    <ProductPage photos={this.state.photos} products={this.state.products}>
      <Heading2 className={styles.green}>
      {t('Products.BoazeriaTitle')}
      </Heading2>
      <Body1 className={styles.productTextBody}>
      {t('Products.BoazeriaText.0')}
        <br/>
        <br/>
        {t('Products.BoazeriaText.1')}
        <br/>
        <br/>
        {t('Products.BoazeriaText.2')}
        <ul>
           <li>{t('Products.BoazeriaText.3')}</li>
          <ul>
            <li><b>14mm x 90mm x 2500mm</b></li>
            <li><b>14mm x 90mm x 3000mm</b></li>
            <li><b>14mm x 105mm x 2500mm</b></li>
            <li><b>14mm x 105mm x 3000mm</b></li>
          </ul> 
        <li>{t('Products.BoazeriaText.4')}</li>
          <ul>
          <li><b>23mm x 130mm x 2500mm</b></li>
          <li><b>23mm x 130mm x 3000mm</b></li>
          </ul>
        
      
        </ul>
          </Body1>
    </ProductPage>
    );
}};

export default withTranslation()(Boazeria);