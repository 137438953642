import React, { Component } from 'react';
import ReactGa from "react-ga";
import { Grid, Modal, Image, Divider } from 'semantic-ui-react';
import styles from './Gallery.module.css'
import Title from './Title';

class Gallery extends Component {

  componentDidMount() {
    ReactGa.pageview(window.location.pathname)
  }

  state = {
    photos: [
      {
        path: `gallery/1.jpg`,
      }, 
      {
        path: `gallery/2.jpg`,
      }, 
      {
        path: `gallery/3.jpg`,
      }, 
      {
        path: `gallery/4.jpg`,
      }, 
      {
        path: `gallery/5.jpg`,
      }, 
      {
        path: `gallery/6.jpg`,
      }, 
      {
        path: `gallery/7.jpg`,
      }, 
      {
        path: `gallery/8.jpg`,
      }, 
      {
        path: `gallery/9.jpg`,
      }, 
      {
        path: `gallery/10.jpg`,
      }, 
      {
        path: `gallery/11.jpg`,
      }, 
      {
        path: `gallery/12.jpg`,
      }, 
      {
        path: `gallery/13.jpg`,
      }, 
      {
        path: `gallery/14.jpg`,
      }, 
      {
        path: `gallery/15.jpg`,
      },
      {
        path: `gallery/16.jpg`,
      },
      {
        path: `gallery/17.jpg`,
      },
      {
        path: `gallery/18.jpg`,
      },
      {
        path: `gallery/19.jpg`,
      },
      {
        path: `gallery/20.jpg`,
      },
      {
        path: `gallery/21.jpg`,
      },
      {
        path: `gallery/22.jpg`,
      }, 
      {
        path: `gallery/23.jpg`,
      },
      {
        path: `gallery/24.jpg`,
      },
    ]
  };
  render() {
    return (
      <div className={styles.gallery}>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Title/>
          </Grid.Column>
        </Grid.Row>
        <Divider/>
        <Grid.Row columns={4} className={styles.galleryRow}> 
          {this.state.photos.map((photo, i) => (
            <Grid.Column className={styles.galleryColumn} mobile={16} computer={4} key={`${photo.path}-${i}`}>
             <Modal trigger={<img src={photo.path} alt="" className={styles.galleryPhoto}/>} centered>
                <Modal.Content image>
                   <Image
                  fluid
                  src={photo.path}
                  />
                </Modal.Content>
              </Modal>
            </Grid.Column>
          ))}
        </Grid.Row> 
      </Grid>
      </div>
      );
  }
}

export default Gallery;
