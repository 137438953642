import React, {Component} from 'react';
import ReactGa from "react-ga";
import ProductPage from '../../ProductPage';
import {Heading2, Body1} from '../../value/Styled';
import { withTranslation } from 'react-i18next';
import styles from './Slupy_schodowe.module.css';

class Slupy_schodowe extends Component {

  componentDidMount() {
    ReactGa.pageview(window.location.pathname)
  }

  state={
    photos: [
      {
        path: `images/slup1.jpg`,
      },  
    ],
    products: [
      {
        path: `images/pellet1.jpg`,
        url: `/products--pellet-drzewny`,
        title: 'Pellet',
      }, 
      {
        path: `images/klej1.jpg`,
        url: `/products--klejonka`,
        title: 'Klejonka',
      },
      {
        path: `images/tralka1.jpg`,
        url: `/products--tralki`,
        title: 'Tralki',
      }, 
    ]
  }
  render(){
    const { t }= this.props;
  return (
    <ProductPage photos={this.state.photos} products={this.state.products}>
      <Heading2 className={styles.green}>
      {t('Products.SlupyTitle')}
      </Heading2>
      <Body1 className={styles.productTextBody}>
      {t('Products.SlupyText.0')}
        <br/>
        <br/>
        {t('Products.SlupyText.1')}
        <ul>
        <li><b>90mm x 90mm x 1500mm</b></li>
        <li><b>90mm x 90mm x 1800mm</b></li>
        <li><b>90mm x 90mm x 2100mm</b></li>
        <li><b>90mm x 90mm x 2400mm</b></li>
        <li><b>90mm x 90mm x 3000mm</b></li>
        <li><b>90mm x 90mm x 3600mm</b></li>
        <li><b>90mm x 90mm x 4200mm</b></li>
        </ul> 
        <b>  
        {t('Products.SlupyText.2')}
        </b>
        <br/>
        <br/>

        {t('Products.SlupyText.3')}
        
      </Body1>
    </ProductPage>
    );
}};

export default withTranslation()(Slupy_schodowe);