import React, { Component } from 'react';
import ReactGa from "react-ga";
import { Heading1, Heading2, Body1 } from '../value/Styled';
import { Grid, Container, Divider } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import styles from './News.module.css'

class News extends Component {

  componentDidMount() {
    ReactGa.pageview(window.location.pathname)
  }

  // state = {
  //   news: [
  //     {
  //       title: 'Aktualności nr 1',
  //       date: '24.04.2020',
  //       text: `<p>Wzrost konkurencyjności i rozwój PPUH TRAK poprzez uruchomienie nowoczesnej linii i wprowadzenie nowych produktów
  //       Poziom dofinansowania z EFRR: 38,85%
  //       Inwestycja została zrealizowana w okresie 01.04.2015 – 26.06.2015
  //       Beneficjent: PPUH TRAK Sp. z o.o. Tomasz Kwaśnik Zdzisław Przerwa
  //       Projekt współfinansowany przez Unię Europejską ze środków Europejskiego Funduszu Rozwoju Regionalnego w ramach Regionalnego Programu Operacyjnego Województwa Mazowieckiego 2007-2013 oraz ze środków budżetu województwa mazowieckiego
  //     `,
  //       photo: `images/mazowsze.png`
  //     }, 
  //     {
  //       title: 'Aktualności nr 2',
  //       date: '15.04.2020',
  //       text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has'
  //     }, 
  //     {
  //       title: 'Aktualności nr 3',
  //       date: '07.03.2020',
  //       text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has'
  //     }, 
  //   ]
  // };
  render() {
    const { t }= this.props;
    return (
      <div className={styles.news} style={{
        background: 'url("images/borowka1.jpg")', 
        }}>
      <Grid>
        <Container className={styles.newsContain}>
        <Grid.Row>
          <Grid.Column>
          <Heading1 className={styles.green}>{t('Company.News')}</Heading1>
          </Grid.Column>
        </Grid.Row>
          {/* {this.state.news.map((news, i) => (
            <Grid.Row className={styles.productRow} mobile={7} computer={4} key={`${news.date}-${i}`}>
              <Divider/>
              <Heading2>
                {news.title}
              </Heading2>
              <Body3 className={styles.newsDate}>
                {news.date}
              </Body3>
              <Body1 className={styles.newsText}>
                {news.text}
                {console.log(news.text)}
              </Body1>
            </Grid.Row>
          ))} */}
          <Grid.Row className={styles.productRow} mobile={7} computer={4}>
          <Divider/>
          <Heading2>
           Wsparcie w ramach tarczy antykryzysowej COVID-19
          </Heading2>
          <Body1 className={styles.newsText}>
            <img src="images/UE_1.png" alt="" className={styles.newsMazoImg}/>
            <br/>
            <b>
               Dotacja na kapitał obrotowy dla PPUH TRAK SP Z O.O.
            </b>
            <br/>
               Projekt dotyczy wsparcia przedsiębiorcy w zakresie zapewnienia płynności finansowej oraz wsparcia bieżącej działalności w związku z trudnościami finansowymi, które wystąpiły u przedsiębiorcy wskutek epidemii COVID-19. Pomoc finansowa udzielana na podstawie programu nr SA.57015 (2020/N)
            <br/>
            <br/>
               Okres realizacji projektu 01/07/2020 – 30/09/2020
            <br/>
            <br/>
            <b>
               Projekt w ramach działania 3.4 Dotacje na kapitał obrotowy Programu Operacyjnego Inteligentny Rozwój 2014-2020, współfinansowany ze środków Europejskiego Funduszu Rozwoju Regionalnego
            </b>
            <br/>
          </Body1>
        </Grid.Row>
           <Grid.Row className={styles.productRow} mobile={7} computer={4}>
          <Divider/>
          <Heading2>
            Projekty nowoczesnych linii
          </Heading2>
          <Body1 className={styles.newsText}>
            <img src="images/mazowsze.png" alt="" className={styles.newsMazoImg}/>
            <br/>
            <br/>
            <b>Wzrost konkurencyjności i rozwój PPUH TRAK poprzez uruchomienie nowoczesnej linii i wprowadzenie nowych produktów</b>
            <br/>
            <br/>
            Poziom dofinansowania z EFRR: 38,85%  
            <br/>
            Inwestycja została zrealizowana w okresie 01.04.2015 – 26.06.2015
            <br/>
            Beneficjent: PPUH TRAK Sp. z o.o. Tomasz Kwaśnik Zdzisław Przerwa
            <br/>
            <br/>
            <b>Projekt współfinansowany przez Unię Europejską ze środków Europejskiego Funduszu Rozwoju Regionalnego w ramach Regionalnego Programu  Operacyjnego           Województwa Mazowieckiego 2007-2013 oraz ze środków budżetu województwa mazowieckiego</b>
          </Body1>
        </Grid.Row>
        </Container>
      </Grid>
      </div>
      );
  }
}

export default withTranslation()(News);
