import React, {Component} from 'react';
import ReactGa from "react-ga";
import ProductPage from '../../ProductPage';
import {Heading2, Body1} from '../../value/Styled';
import { withTranslation } from 'react-i18next';
import styles from './Klejonka.module.css';

class Klejonka extends Component {

  componentDidMount() {
    ReactGa.pageview(window.location.pathname)
  }

  state={
    photos: [
      {
        path: `images/klej1.jpg`,
      }, 
      {
        path: `images/klej2.jpg`,
      }, 
      {
        path: `images/klej3.jpg`,
      },
      {
        path: `images/klej4.jpg`,
      }, 
      {
        path: `images/klej5.jpg`,
      },  
      {
        path: `images/klej6.jpg`,
      }, 
      {
        path: `images/klej7.jpg`,
      }, 
      {
        path: `images/klej8.jpg`,
      }, 
    ],
    products: [
      {
        path: `images/pellet1.jpg`,
        url: `/products--pellet-drzewny`,
        title: 'Pellet',
      }, 
      {
        path: `images/tralka1.jpg`,
        url: `/products--tralki`,
        title: 'Tralki',
      }, 
      {
        path: `images/slup1.jpg`,
        url: `/products--słupy-schodowe`,
        title: 'Słupy',
      }, 
    ]
  }
  render(){
    const { t }= this.props;
  return (
    <ProductPage photos={this.state.photos} products={this.state.products}>
      <Heading2 className={styles.green}>
      {t('Products.KlejonkaTitle')}
      </Heading2>
      <Body1 className={styles.productTextBody}>
      {t('Products.KlejonkaText.0')}
      <br/>
      <br/>
      {t('Products.KlejonkaText.1')} 
      <br/>
      <br/>
      {t('Products.KlejonkaText.2')}<a href="/certificates"> <b> {t('Shorts.CheckHere')}</b></a>
      <br/>
      <br/>
      {t('Products.KlejonkaText.3')}
      <br/>
      <br/>
      {t('Products.KlejonkaText.4')}
      <ul>
      <li> {t('Products.KlejonkaText.5')} <b>30-150mm</b></li>
      <li> {t('Products.KlejonkaText.6')} <b>40-230mm</b></li>
      </ul>
      {t('Products.KlejonkaText.7')}
      <ul>
        <li> <b>700-3000mm</b> - {t('Products.KlejonkaText.8')} </li>
      </ul>
      {t('Products.KlejonkaText.9')}
      </Body1>
    </ProductPage>
    );
}};

export default withTranslation()(Klejonka);