import React, { Component } from 'react';
import ReactGa from "react-ga";
import ProductPage from '../../ProductPage';
import {Heading2, Body1} from '../../value/Styled';
import { withTranslation } from 'react-i18next';
import styles from './Porecze.module.css';

class Porecze extends Component {

  componentDidMount() {
    ReactGa.pageview(window.location.pathname)
  }

  state={
    photos: [
      {
        path: `images/porecze1.jpg`,
      },  
      {
        path: `images/porecze2.jpg`,
      },
      {
        path: `images/porecze3.jpg`,
      },  
    ],
    products: [
       {
        path: `images/pellet1.jpg`,
        url: `/products--pellet-drzewny`,
        title: 'Pellet',
      }, 
      {
        path: `images/klej1.jpg`,
        url: `/products--klejonka`,
        title: 'Klejonka',
      },
      {
        path: `images/tralka1.jpg`,
        url: `/products--tralki`,
        title: 'Tralki',
      }, 
    ]
  }
  render(){
    const { t }= this.props;
  return (
    <ProductPage photos={this.state.photos} products={this.state.products}>
      <Heading2 className={styles.green}>
      {t('Products.PoreczeTitle')}
      </Heading2>
      <Body1 className={styles.productTextBody}>
      {t('Products.PoreczeText.0')}
        <br/>
        <br/>
        {t('Products.PoreczeText.1')} <b>58mm x 58mm</b>
        <br/>
        <br/>
        {t('Products.PoreczeText.2')}
        <ul>
          <li>{t('Products.PoreczeText.3')} <b>700-4500mm</b></li>
          <li>{t('Products.PoreczeText.4')}  <b>700-6300mm</b></li>
        </ul>
      </Body1>
    </ProductPage>
    );
}};

export default withTranslation()(Porecze);
