import React from 'react';
import styles from './Styled.module.css';

const Heading1 = (props) => {
  return (
    <h1 className={`${styles.head1} ${props.className}`}>
      {props.children}
    </h1>
  );
};
const Heading2 = (props) => {
  return (
    <h2 className={`${styles.head2} ${props.className}`}>
      {props.children}
    </h2>
  );
};
const Heading3 = (props) => {
  return (
    <h3 className={`${styles.head3} ${props.className}`}>
      {props.children}
    </h3>
  );
};
const Body1 = (props) => {
  return (
    <p className={`${styles.bod1} ${props.className}`}>{props.children}</p>
  );
};
const Body2 = (props) => {
  return (
    <p className={`${styles.bod2} ${props.className}`}>{props.children}</p>
  );
};
const Body3 = (props) => {
  return (
    <p className={`${styles.bod3} ${props.className}`}>{props.children}</p>
  );
};
export {
  Heading1,
  Heading2,
  Heading3,
  Body1,
  Body2,
  Body3,
};
