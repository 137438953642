import React, {Component} from 'react';
import ReactGa from "react-ga";
import ProductPage from '../../ProductPage';
import {Heading2, Body1} from '../../value/Styled';
import { withTranslation } from 'react-i18next';
import styles from './Pellet.module.css';

class Pellet extends Component {

  componentDidMount() {
    ReactGa.pageview(window.location.pathname)
  }

  state={
    photos: [
      {
        path: `images/pellet1.jpg`,
      },
      {
        path: `images/pellet2.jpg`,
      }, 
      {
        path: `images/pellet3.jpg`,
      }, 
      {
        path: `images/pellet4.jpg`,
      }, 
      {
        path: `images/pellet5.jpg`,
      }, 
      {
        path: `images/pellet6.jpg`,
      }, 
    ],
    products: [
      {
        path: `images/klej1.jpg`,
        url: `/products--klejonka`,
        title: 'Klejonka',
      },
      {
        path: `images/tralka1.jpg`,
        url: `/products--tralki`,
        title: 'Tralki',
      }, 
      {
        path: `images/slup1.jpg`,
        url: `/products--słupy-schodowe`,
        title: 'Słupy',
      }, 
    ]
  }
  render(){
    const { t }= this.props;
  return (
    <ProductPage photos={this.state.photos} products={this.state.products}>
      <Heading2 className={styles.green}>
      {t('Products.PelletTitle')}
          </Heading2>
          <Body1 className={styles.productTextBody}>
          {t('Products.PelletText.0')}
            <br/><br/>
            {t('Products.PelletText.1')}
            <br/><br/>
            {t('Products.PelletText.2')}
            <ul>
            <li>{t('Products.PelletText.3')}  ≥ <b>16.5 MJ/kg</b></li>
            <li>{t('Products.PelletText.4')} ≤ <b>10%</b></li>
            <li>{t('Products.PelletText.5')} ≤ <b>0,7%</b></li>
            <li>{t('Products.PelletText.6')} ≥ <b>98,0%</b></li>
            </ul>
            {t('Products.PelletText.7')}
            <ul>
            <li>{t('Products.PelletText.8')}</li>
            <li>{t('Products.PelletText.9')}</li>
            </ul>
          </Body1>
    </ProductPage>
    );
}};

export default withTranslation()(Pellet);