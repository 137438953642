import React from 'react';
import {Grid, Responsive} from 'semantic-ui-react';
import {Heading1, Heading2, Body1} from '../value/Styled';
import MainButton from '../Buttons/MainButton';
import { useTranslation } from 'react-i18next';
import styles from './Company.module.css';

const CompanyResp = () =>{
  const { t } = useTranslation();
    return(
      <Responsive maxWidth={992}>
        <Grid>
            {/*------------------------Start----------------------- */}
        <Grid.Row  columns={1} className={styles.companyRow}
      style={{ minHeight: 500,
      background: 'url("images/company.jpg")', 
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      }}
      >
        <Grid.Column computer={8} mobile={16} textAlign='center'>
          <div className={styles.companyStart}>
          <Heading1 className={styles.companyTitle}>
            {t("Company.Check.0")}
            <br/>
            {t("Company.Check.1")}
            <br/>
            {t("Company.Check.2")}
            <br/>
            {t("Company.Check.3")}
          </Heading1>
          <Body1 className={styles.companyTitleB}>{t("Company.Elite")}</Body1>
          </div>
        </Grid.Column>
      </Grid.Row>

       {/*------------------------Opis firmy----------------------- */}

      <Grid.Row columns={1} className={styles.companyRow}>    
        <Grid.Column width={16} className={styles.companyInfo}>
          <div className={styles.companyInfoText}>
            <Heading2 className={styles.companyInfoTextTitle}>{t("Company.Title")}</Heading2><br/>
            <Body1> 
            {t("Company.Text.0")} 
            <br/>
            <br/>
            {t("Company.Text.1")}
            <br/>
            </Body1><br/>
            <a href="/products">
            <MainButton className={styles.companyMainButton}>{t('Products.1')}</MainButton>
            </a>
          </div>
        </Grid.Column>
      </Grid.Row>

            {/*------------------------Więcej----------------------- */}

      <Grid.Row columns={1} className={styles.companyMoreInfo}>
        <Grid.Column>
          <div className={styles.companyMoreInfoBack}>
              <Heading2>{t("Shorts.Furthermore")}</Heading2><br/>
              <Body1>
              {t("Company.More.0")}
              <br/>
              <br/>
              {t("Company.More.1")}
              <br/>
              <br/>
              {t("Company.More.2")}
                <br/>
                <br/>
                {t("Company.More.3")}
              </Body1>
                <a href="/news">
                  <MainButton className={styles.companyButton}>
                  {t('Company.News')}
                  </MainButton> 
                </a>
                <a href="/certificates">
                  <MainButton className={styles.companyButton}>
                  {t('Company.Certificates')}
                  </MainButton> 
                </a>
                <a href="/Rodo">
                  <MainButton className={styles.companyButton}>
                  {t('Company.Rodo')}
                  </MainButton> 
                </a>
          </div>
        </Grid.Column>
      </Grid.Row>

 {/*-----------------------Projekty----------------------- */}
      
      {/* <Grid.Row columns={1} className={styles.companyBackground}>
        <Grid.Column textAlign="center" width={16}>
          <Heading2 className={styles.companyGreen}>
            Projekty
          </Heading2>
        </Grid.Column>
      </Grid.Row>   
      <Grid.Row columns={2} reversed="computer" className={styles.companyBackground}>
        <Grid.Column mobile={16} className={styles.companyProjectImage}>
          <img src='images/proba5.jpg' className={styles.ImagesCover} alt=''/>
        </Grid.Column>
        <Grid.Column mobile={16} className={styles.companyProjectColumn}>
          <Heading3 className={styles.companyGreen}>
            Nazwa projektu
          </Heading3>
          <Body1>
          To opis Twojego projektu. Niezależnie od tego, czy Twoja praca bazuje na tekście, fotografiach czy wideo, krótkie streszczenie na pewno pozwoli odwiedzającym witrynę lepiej poznać i zrozumieć Twój projekt. Wykorzystaj część poświęconą mediom, aby pochwalić się swoją pracą!
          </Body1>
        </Grid.Column>
      </Grid.Row>        
      <Grid.Row columns={2} reversed="computer" className={styles.companyBackground}>
        <Grid.Column mobile={16} className={styles.companyProjectImage}>
          <img src='images/proba2.jpg' className={styles.ImagesCover} alt=''/>
        </Grid.Column>
        <Grid.Column mobile={16} className={styles.companyProjectColumn}>
          <Heading3 className={styles.companyGreen}>
            Nazwa projektu
          </Heading3>
          <Body1>
          To opis Twojego projektu. Niezależnie od tego, czy Twoja praca bazuje na tekście, fotografiach czy wideo, krótkie streszczenie na pewno pozwoli odwiedzającym witrynę lepiej poznać i zrozumieć Twój projekt. Wykorzystaj część poświęconą mediom, aby pochwalić się swoją pracą!
          </Body1>
        </Grid.Column>
      </Grid.Row> 
      <Grid.Row columns={2} reversed="computer" className={styles.companyBackground}>
        <Grid.Column mobile={16} className={styles.companyProjectImage}>
          <img src='images/proba4.jpg' className={styles.ImagesCover} alt=''/>
        </Grid.Column>
        <Grid.Column mobile={16} className={styles.companyProjectColumn}>
          <Heading3 className={styles.companyGreen}>
            Nazwa projektu
          </Heading3>
          <Body1>
          To opis Twojego projektu. Niezależnie od tego, czy Twoja praca bazuje na tekście, fotografiach czy wideo, krótkie streszczenie na pewno pozwoli odwiedzającym witrynę lepiej poznać i zrozumieć Twój projekt. Wykorzystaj część poświęconą mediom, aby pochwalić się swoją pracą!
          </Body1>
        </Grid.Column>
      </Grid.Row>    */}
      </Grid>
      </Responsive>
    );
  }

export default CompanyResp;