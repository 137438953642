import React, {Component} from 'react';
import styles from './App.module.css';
// import { IntlProvider, addLocaleData } from 'react-intl';
import { Route, Switch } from 'react-router-dom';
import ReactGa from "react-ga";

import Header from './components/Header';
import Home from './components/Home';
import Company from './components/Company';
import Rodo from './components/Rodo';
import Certificates from './components/Certificates';
import News from './components/News';
import Gallery from './components/Gallery';
import Products from './components/Products';
  import Boazeria from './components/Products/Boazeria';
  import Borowka from './components/Products/Borowka';
  import Blaty from './components/Products/Blaty';
  import Porecze from './components/Products/Porecze';
  import Deska_tarasowa from './components/Products/Deska_tarasowa';
  import Drewno_opalowe from './components/Products/Drewno_opalowe';
  import Deska_podlogowa from './components/Products/Deska_podlogowa';
  import Kora_ozdobna from './components/Products/Kora_ozdobna';
  import Szalowka from './components/Products/Szalowka';
  import Pellet from './components/Products/Pellet';
  import Slupy_schodowe from './components/Products/Slupy_schodowe';
  import Klejonka from './components/Products/Klejonka';
  import Laty_i_kontrlaty from './components/Products/Laty_i_kontrlaty';
  import Tarcica from './components/Products/Tarcica';
  import Tralki from './components/Products/Tralki';
  import Trepy_schodowe from './components/Products/Trepy_schodowe';
import Contact from './components/Contact';
import Footer from './components/Footer';


const TRACKING_ID = "G-K6HEF313KQ";
ReactGa.initialize(TRACKING_ID);

class App extends Component {
  render() {
    return(  
     <div className={styles.App}>
       <Header/>
       <Switch>
        <Route
        exact={true}
        path="/"
        component={Home}
        />
        <Route
        exact={true}
        path="/company"
        component={Company}
        />
        <Route
        exact={true}
        path="/news"
        component={News}
        />
        <Route
        exact={true}
        path="/RODO"
        component={Rodo}
        /><Route
        exact={true}
        path="/galler"
        component={Gallery}
        /><Route
        exact={true}
        path="/products"
        component={Products}
        /><Route
        exact={true}
        path="/certificates"
        component={Certificates}
        /><Route
        exact={true}
        path="/contact"
        component={Contact}
        /><Route
        exact={true}
        path="/products--deska-podłogowa"
        component={Deska_podlogowa}
        /><Route
        exact={true}
        path="/products--tralki"
        component={Tralki}
        /><Route
        exact={true}
        path="/products--poręcze"
        component={Porecze}
        /><Route
        exact={true}
        path="/products--szalówka"
        component={Szalowka}
        /><Route
        exact={true}
        path="/products--klejonka"
        component={Klejonka}
        /><Route
        exact={true}
        path="/products--blaty"
        component={Blaty}
        /><Route
        exact={true}
        path="/products--słupy-schodowe"
        component={Slupy_schodowe}
        /><Route
        exact={true}
        path="/products--trepy-schodowe"
        component={Trepy_schodowe}
        /><Route
        exact={true}
        path="/products--pellet-drzewny"
        component={Pellet}
        /><Route
        exact={true}
        path="/products--łaty-i-kontrłaty"
        component={Laty_i_kontrlaty}
        /><Route
        exact={true}
        path="/products--drewno-opałowe"
        component={Drewno_opalowe}
        /><Route
        exact={true}
        path="/products--tarcica"
        component={Tarcica}
        /><Route
        exact={true}
        path="/products--boazeria"
        component={Boazeria}
        /><Route
        exact={true}
        path="/products--deska-tarasowa"
        component={Deska_tarasowa}
        /><Route
        exact={true}
        path="/products--kora-ozdobna"
        component={Kora_ozdobna}
        /><Route
        exact={true}
        path="/products--trociny-pod-borówkę"
        component={Borowka}
        />
       </Switch>
        <Footer/>
     </div>
    );
    }
}

export default App;
