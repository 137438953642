import React, {Component} from 'react';
import HeaderItemsResp from './HeaderItemsResp';
import {
  Container,
  Image,
  Menu,
  Responsive,
  Sidebar,
  Icon
} from 'semantic-ui-react';
import {Heading3} from '../value/Styled';
import styles from './Header.module.css';

class TopHeaderResp extends Component{

  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

render(){

  const { sidebarOpened } = this.state

return(
    <Responsive maxWidth={992}>
      <Sidebar
          as={Menu}
          animation='push'
          direction='right'
          onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened}
          inverted
      >
       <HeaderItemsResp/>
    </Sidebar>
    <Sidebar.Pushable>
      <Sidebar.Pusher >
        <Menu 
          size='large'
          className={styles.topMenu}
          inverted
          borderless
        >
          <Container>
            <Menu.Item as='a' href='/' header >   
             <Image className={styles.logo} src='images/logoTrak.svg' />
               <nav className={styles.navigation}>
               <Heading3 className={styles.title}>PPUH <b>TRAK</b><br/> Sp. z o.o. </Heading3>
               </nav>
            </Menu.Item>
            <Menu.Item position='right' onClick={this.handleToggle}>
              <Icon  name='sidebar'/>
            </Menu.Item>
          </Container>
        </Menu>
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  </Responsive>
)}};

export default TopHeaderResp
