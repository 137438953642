import React from 'react';
import { Body1 } from '../value/Styled';
import { useTranslation } from 'react-i18next';
import styles from './Certificates.module.css'

const ENplus_text = () => {
    const { t } = useTranslation();
    return (
        <Body1 className={styles.certyBody}>
            <b>2. ENplus</b><sup>®</sup> – {t('Certificates.ENplus')}  
        </Body1>
    )
}
export default ENplus_text;